
import { Options, Vue } from 'vue-class-component'
import theNews from '@/components/Frontend/News/index.vue'

@Options({
  components: {
    theNews
  }
})
export default class NewsView extends Vue {}
