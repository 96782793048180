import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import BaseFunctions from '@/components/Utility/Base'
import { Posts } from '@/services/PostsDataService'
import SysPost, { SysPostCategory, SysPostVersion } from '@/types/SysPost'

type postDataType = { postStatus: boolean; postTitle: string; postContents: string; postReleased: string; postCategory: string; postViews: number; postId: number; }
type dataReturnType = { error: any; }

@Options({
  props: {
    instanceType: Number
  }
})
export default class News extends Vue {
  instanceType!: number
  error: any = null
  private tempPost = {} as SysPost
  postData: postDataType = { postStatus: true, postTitle: '', postContents: '', postReleased: '', postCategory: '', postViews: 0, postId: 0 }

  readonly name : string = 'News'
  data (): dataReturnType {
    return {
      error: this.error
    }
  }

  public getIndexOfPostVersion (postVersion: number, singlePostEntryObj: SysPost = this.tempPost) : number {
    // const retVal = this.indlaegs.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    const retVal = singlePostEntryObj.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    // console.log('Index of post version = ' + retVal + ' ; post versioning object = ' + JSON.stringify(singlePostEntryObj.indlaeg_versionerings_id))

    return (retVal === undefined ? -1 : retVal)
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public retrieveNewsItem () : void {
    const route = useRoute()
    const postSlug = route.params.indlaegSlug

    Posts.PostsDataService.getBySlug(postSlug.toString())
      .then((response) => {
        this.tempPost = response.data[0]
        console.log(response.data)

        this.postData = {
          postStatus: this.tempPost.indlaeg_status,
          postTitle: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_titel,
          postContents: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_indhold,
          postReleased: new Date(this.tempPost.indlaeg_udgivet).toLocaleDateString('da-DK'),
          postCategory: this.tempPost.indlaeg_kategori_id.indlaeg_kategori_titel,
          postViews: Number(this.tempPost.indlaeg_visninger),
          postId: Number(this.tempPost.id)
        }
        this.postData.postViews += 1
        const updatePostsData = {
          indlaeg_visninger: this.postData.postViews
        }

        Posts.PostsDataService.update(this.postData.postId.toString(), updatePostsData)
          .then((response) => {
            console.log(response.data)
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    this.retrieveNewsItem()
  }
}
